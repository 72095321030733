<template>
  <v-container>
    <div class="folha">
      <div style="clear:both;width:21cm;margin:0 auto;">
        <table style="min-width:21cm;">
          <tbody>
            <tr>
              <td colspan="3">
                <div class="space"></div>
                <div
                  style="margin:0 auto;position:relative;display:table;clear:both;width:99.4%"
                >
                  <div>
                    <div style="border:1px solid #000;font-weight:normal;">
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;"
                      >
                        <img
                          src="https://boleto.easycob-n.com.br/assets/ALGAR_TELECOM.jpg"
                          style="height:55px;margin:4px;margin-bottom:10px;"
                        />
                      <!-- <img
                          src="https://boleto.easycob-n.com.br/assets/easycob.png"
                          style="height:45px;margin:5px;float:right"
                        /> --> 
                      </div>
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;"
                      >
                        Prezado cliente, {{ NOMCLIEN }}
                      </div>
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;"
                      >
                        Anexamos boleto bancário com vencimento em
                        {{ venc | dataF }} no valor de R$ {{ VALPAGO }},
                        referente ao pagamento da parcela do acordo citado
                        abaixo.
                      </div>
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;margin-top:8px;"
                      >
                        Parcelas do Acordo referente ao(s) contrato(s):
                      </div>

                      <div style="width:23%;margin:3px 1%;float:left;">
                        Parcela
                      </div>
                      <div style="width:23%;margin:3px 1%;float:left;">
                        Vencimento
                      </div>
                      <div style="width:23%;margin:3px 1%;float:left;">
                        Valor
                      </div>
                      <div style="width:23%;margin:3px 1%;float:left;">
                        Situacao
                      </div>

                      <div style="width:23%;margin:3px 1%;float:left;">001</div>
                      <div style="width:23%;margin:3px 1%;float:left;">
                        {{ venc | dataF }}
                      </div>
                      <div style="width:23%;margin:3px 1%;float:left;">
                        {{ VALPAGO }}
                      </div>
                      <div style="width:23%;margin:3px 1%;float:left;">
                        EM ABERTO
                      </div>

                      <div
                        style="width:98%;line-height:120% !important;margin:1%;margin-top:37%;"
                      >
                        Este acordo contempla unicamente a(s) parcela(s) abaixo
                        relacionada(s):
                      </div>
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;"
                      >
                        {{ INSTRUCAO }} <br />
                      </div>
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;"
                      ></div>
                      <div
                        style="width:98%;line-height:120% !important;margin:1%;padding-bottom:0.2cm;text-align:right"
                      >
                        <code>Cliente: {{ DESCPF }}/ Acordo: {{ acordo }}</code>
                      </div>
                    </div>
                    <table class="tbl">
                      <tr>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>Total das originais:</code><br /><span
                            class="mnSt"
                            style="float:right"
                            >{{ VALORPRINCIPAL }}</span
                          >
                        </td>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>Total da multa:</code><br /><span
                            class="mnSt"
                            style="float:right"
                            >{{ VALORMULTA }}</span
                          >
                        </td>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>Total das taxas:</code><br /><span
                            class="mnSt"
                            style="float:right"
                            >{{ VALORTXADM }}</span
                          >
                        </td>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>Total dos juros:</code><br /><span
                            class="mnSt"
                            style="float:right"
                            >{{ VALORJUROS }}</span
                          >
                        </td>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>Total de desconto:</code><br /><span
                            class="mnSt"
                            style="float:right"
                            >{{ VALORDESCONTO }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr>
                        <td
                          style="border:1px solid #000;min-width:5.25cm;border-top:none;"
                        >
                          <code>Pagador:</code><br /><span class="mnSt"
                            >{{ NOMCLIEN }}
                          </span>
                        </td>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>Vencimento:</code><br /><span class="mnSt">{{
                            venc | dataF
                          }}</span>
                        </td>
                        <td
                          style="border:1px solid #000;min-width:2.25cm;border-top:none;"
                        >
                          <code>(=) Valor Cobrado:</code><br /><span
                            class="mnSt"
                            >{{ VALPAGO }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr>
                        <td
                          style="border:1px solid #000;min-width:3.25cm;border-top:none;"
                        >
                          <code>Agência / Código do Beneficiário:</code
                          ><br /><span class="mnSt"
                            >{{ DESAGENC }}/{{ DESCONTA }}-{{
                              DESAGENCDV
                            }}</span
                          >
                        </td>
                        <td
                          style="border:1px solid #000;min-width:3.25cm;border-top:none;"
                        >
                          <code>Nosso número:</code><br /><span class="mnSt">{{
                            NOSSONUMERO
                          }}</span>
                        </td>
                        <td
                          style="border:1px solid #000;min-width:3.25cm;border-top:none;"
                        >
                          <code> </code><br /><span class="mnSt"> </span>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- ///////////////////////////////////////////////////////// -->
                  <div class="pagebreak"></div>
                  <!-- ///////////////////////////////////////////////////////// -->
                  <div>
                    <br /><br /><br /><br /><br /><br /><br /><br />
                    <table class="tbl">
                      <tr>
                        <td style="width:1cm;position:relative;">
                          <img
                            src="https://boleto.easycob-n.com.br/assets/caixa.png"
                            style="height:35px;margin: auto 5px;"
                          />
                          <div class="vertical"></div>
                        </td>
                        <td style="min-width:2cm;position:relative;">
                          <h1
                            style="font-size:160%;margin:0px 10px;margin-top:20px;"
                          >
                            {{ CODBANCO }}-{{ DESBANCODV }}
                          </h1>
                          <div class="vertical"></div>
                        </td>
                        <td style="min-width:12cm;text-align:right;">
                          <h4 style="margin:0px 10px;margin-top:20px">
                            RECIBO DO SACADO
                          </h4>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:8.3cm;">
                          <code>Local de Pagamento</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >PREFERENCIALMENTE NAS CASAS LOTÉRICAS ATÉ O VALOR
                            LIMITE</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm;background:#e8e8e8;"
                        >
                          <code style="background:none">Vencimento</code
                          ><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ venc | dataF }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:7.3cm;">
                          <code>Cedente</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >ALGAR TELECOM S.A</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:3cm;"
                        >
                          <code>CPF/CNPJ</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ DESCPF }}></span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm"
                        >
                          <code>Agência/Código Cedente</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ AGENCIACONTA }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:7.3cm;">
                          <code>Endereço do Cedente</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >{{ DESENDER }} {{ DESNUMER }} {{ DESCOMPL }}
                            {{ DESBAIRR }} {{ DESCIDAD }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:0.8cm;"
                        >
                          <code>UF</code><br /><span class="mnSt"
                            >{{ DESESTAD }}
                          </span>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:2.95cm;"
                        >
                          <code>CEP</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ DESCEP }}
                          </span>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:2.95cm;">
                          <code>Data do Documento</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >{{ datahoje | dataF }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:1.8cm;"
                        >
                          <code>Num do Documento</code><br /><span
                            class="mnSt"
                            >{{ DESNUMDOC }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:0.8cm;"
                        >
                          <code>Espécie Doc.</code><br /><span class="mnSt"
                            >DS</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:0.8cm;"
                        >
                          <code>Aceite</code><br /><span class="mnSt">N</span>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:1.8cm;"
                        >
                          <code>Data do Processamento</code><br /><span
                            class="mnSt"
                            >{{ datahoje | dataF }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm;"
                        >
                          <code>Nosso Número</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ NOSSONUMERO }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td
                          style="border-top:1px solid #000;max-width:5cm;min-width:5cm;"
                        >
                          <code>Uso do Banco</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                          >
                          </span>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;max-width:1.5cm;min-width:1.5cm;"
                        >
                          <code>Carteira</code><br /><span class="mnSt"
                            >RG</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;max-width:1.5cm;min-width:1.5cm;"
                        >
                          <code>Espécie Moeda</code><br /><span class="mnSt"
                            >R$</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;max-width:6.15cm;min-width:6.15cm;"
                        >
                          <table class="tbl">
                            <tr style="height:15px">
                              <td>
                                <code>Quantidade Moeda</code
                                ><code
                                  style="margin:0 10%;border-right:1px solid #000;"
                                ></code
                                ><code>Valor</code>
                                <div style="position:relative;">
                                  <div
                                    style="position:absolute;left:41.5%;top:10%;"
                                  >
                                    <code>x</code>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm;background:#e8e8e8;"
                        >
                          <code style="background:none"
                            >(=) Valor do Documento</code
                          ><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ VALPAGO }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="border-top:1px solid #000;">
                        <td
                          rowspan="5"
                          style="border-right:1px solid #000;width:16.15cm;"
                        >
                          <code
                            >Instruções (Todas as informações deste boleto são
                            de exclusiva responsabilidade do cedente)</code
                          ><br />
                          <br /><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >RECEBER SOMENTE EM DINHEIRO</span
                          >
                          <br /><br /><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >NAO RECEBER APOS O VENCIMENTO</span
                          >
                          <br /><br /><span class="mnSt">
                            <div style="padding-left:1cm;padding-bottom:0.15cm">
                              {{ INSTRUCAO }} <br />
                            </div>
                            <div style="padding-left:1cm"></div>
                          </span>
                        </td>
                        <td style="width:4.865cm;">
                          <code>(-) Desconto</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;">
                        <td>
                          <code>(-) Outras Deduções / Abatimento</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;">
                        <td>
                          <code>(+) Mora / Multa / Juros</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;">
                        <td>
                          <code>(+) Outros Acréscimos</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;background:#e8e8e8;">
                        <td>
                          <code style="background:none">(=) Valor Cobrado</code>
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="border-top:1px solid #000;">
                        <td
                          rowspan="5"
                          style="border-right:1px solid #000;min-width:15.95cm;"
                        >
                          <code>Sacado</code><br />
                          <br /><span class="mnSt" style="padding-left:1cm;">{{
                            NOMCLIEN
                          }}</span>
                          <br /><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >{{ DESENDER }} {{ DESNUMER }} {{ DESCOMPL }}</span
                          >
                          <br /><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >{{ DESCEP }} {{ DESCOMPL }} {{ DESBAIRR }}
                            {{ DESESTAD }}</span
                          >
                          <br /><br /><code>Sacador/Avalista</code>
                        </td>
                        <td style="min-width:4.85cm;">
                          <code></code><br />
                          <span class="mnSt">CPF : {{ DESCPF }}</span>
                          <br /><br /><code>Código de baixa</code>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="border-top:1px solid #000;">
                        <td rowspan="5" style="min-width:15.95cm;">
                          <span class="mnSt">
                            <div style="padding-left:1cm">
                              SAC CAIXA: 0800 726 0101 (informações,
                              reclamações, sugestões e elogios)<br />
                            </div>
                            <div style="padding-left:1cm">
                              Para pessoas com deficiência auditiva ou de fala:
                              0800 726 2492<br />
                            </div>
                            <div style="padding-left:1cm">
                              Ouvidoria: 0800 725 7474<br />
                            </div>
                            <div style="padding-left:1cm">caixa.gov.br</div>
                          </span>
                        </td>
                        <td style="min-width:4.85cm;">
                          <code>Autenticação Mecânica</code>
                        </td>
                      </tr>
                    </table>

                    <br />
                  </div>
                  <!-- ///////////////////////////////////////////////////////// -->
                  <div>
                    <br /><br /><br />
                    <table class="tbl">
                      <tr>
                        <td style="width:1cm;position:relative;">
                          <img
                            src="https://boleto.easycob-n.com.br/assets/caixa.png"
                            style="height:35px;margin: auto 5px;"
                          />
                          <div class="vertical"></div>
                        </td>
                        <td style="min-width:2cm;position:relative;">
                          <h1
                            style="font-size:160%;margin:0px 10px;margin-top:20px;"
                          >
                            104-0
                          </h1>
                          <div class="vertical"></div>
                        </td>
                        <td style="min-width:12cm;text-align:right;">
                          <h3
                            class="mnSt"
                            style="font-size:15px;margin-top:20px;margin-bottom:0;"
                          >
                            {{ LINHADIGITAVEL }}
                          </h3>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:8.3cm;">
                          <code>Local de Pagamento</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >PREFERENCIALMENTE NAS CASAS LOTÉRICAS ATÉ O VALOR
                            LIMITE</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm;background:#e8e8e8;"
                        >
                          <code style="background:none">Vencimento</code
                          ><br /><span class="mnSt" style="padding-left:0.5cm">
                            {{ venc }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:7.3cm;">
                          <code>Cedente</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >ALGAR TELECOM S.A</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:3cm;"
                        >
                          <code>CPF/CNPJ</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >71.208.516/0001-74</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm"
                        >
                          <code>Agência/Código Cedente</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >0162/729037-3</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:7.3cm;">
                          <code>Endereço do Cedente</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >{{ DESENDER }} {{ DESNUMER }} {{ DESCOMPL }}
                            {{ DESBAIRR }} {{ DESCIDAD }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:0.8cm;"
                        >
                          <code>UF</code><br /><span class="mnSt">{{
                            DESESTAD
                          }}</span>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:2.95cm;"
                        >
                          <code>CEP</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ DESCEP }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td style="border-top:1px solid #000;min-width:2.95cm;">
                          <code>Data do Documento</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >{{ datahoje | dataF }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:1.8cm;"
                        >
                          <code>Num do Documento</code><br /><span
                            class="mnSt"
                            >{{ DESNUMDOC }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:0.8cm;"
                        >
                          <code>Espécie Doc.</code><br /><span class="mnSt"
                            >DS</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:0.8cm;"
                        >
                          <code>Aceite</code><br /><span class="mnSt">N</span>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;min-width:1.8cm;"
                        >
                          <code>Data do Processamento</code><br /><span
                            class="mnSt"
                            >{{ datahoje | dataF }}</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm;"
                        >
                          <code>Nosso Número</code><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ NOSSONUMERO }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="height:32px">
                        <td
                          style="border-top:1px solid #000;max-width:5cm;min-width:5cm;"
                        >
                          <code>Uso do Banco</code><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                          >
                          </span>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;max-width:1.5cm;min-width:1.5cm;"
                        >
                          <code>Carteira</code><br /><span class="mnSt"
                            >RG</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;max-width:1.5cm;min-width:1.5cm;"
                        >
                          <code>Espécie Moeda</code><br /><span class="mnSt"
                            >R$</span
                          >
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;max-width:6.15cm;min-width:6.15cm;"
                        >
                          <table class="tbl">
                            <tr style="height:15px">
                              <td>
                                <code>Quantidade Moeda</code
                                ><code
                                  style="margin:0 10%;border-right:1px solid #000;"
                                ></code
                                ><code>Valor</code>
                                <div style="position:relative;">
                                  <div
                                    style="position:absolute;left:41.5%;top:10%;"
                                  >
                                    <code>x</code>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </table>
                        </td>
                        <td
                          style="border-top:1px solid #000;border-left:1px solid #000;width:4.85cm;background:#e8e8e8;"
                        >
                          <code style="background:none"
                            >(=) Valor do Documento</code
                          ><br /><span
                            class="mnSt"
                            style="padding-left:0.5cm"
                            >{{ VALPAGO }}</span
                          >
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="border-top:1px solid #000;">
                        <td
                          rowspan="5"
                          style="border-right:1px solid #000;width:16.15cm;"
                        >
                          <code
                            >Instruções (Todas as informações deste boleto são
                            de exclusiva responsabilidade do cedente)</code
                          ><br />
                          <br /><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >RECEBER SOMENTE EM DINHEIRO</span
                          >
                          <br /><br /><br /><span
                            class="mnSt"
                            style="padding-left:1cm;"
                            >NAO RECEBER APOS O VENCIMENTO</span
                          >
                          <br /><br /><span class="mnSt">
                            <div style="padding-left:1cm;padding-bottom:0.15cm">
                              {{ INSTRUCAO }} <br />
                            </div>
                            <div style="padding-left:1cm"></div>
                          </span>
                        </td>
                        <td style="width:4.865cm;">
                          <code>(-) Desconto</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;">
                        <td>
                          <code>(-) Outras Deduções / Abatimento</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;">
                        <td>
                          <code>(+) Mora / Multa / Juros</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;">
                        <td>
                          <code>(+) Outros Acréscimos</code><br />
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                      <tr style="border-top:1px solid #000;background:#e8e8e8;">
                        <td>
                          <code style="background:none">(=) Valor Cobrado</code>
                          <span class="mnSt"> </span>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="border-top:1px solid #000;">
                        <td
                          rowspan="5"
                          style="border-right:1px solid #000;min-width:15.95cm;"
                        >
                          <code>Sacado</code><br />
                          <br /><span class="mnSt" style="padding-left:1cm;">{{
                            NOMCLIEN
                          }}</span>
                          <br /><span class="mnSt" style="padding-left:1cm;"
                            >{{ DESENDER }} {{ DESNUMER }} {{ DESCOMPL }}</span
                          >
                          <br /><span class="mnSt" style="padding-left:1cm;"
                            >{{ DESCEP }} {{ DESCOMPL }} {{ DESBAIRR }}
                            {{ DESESTAD }}</span
                          >
                          <br /><code>Sacador/Avalista</code>
                        </td>
                        <td style="min-width:4.85cm;">
                          <code></code><br />
                          <span class="mnSt">CPF : {{ DESCPF }}</span>
                          <br /><br /><code>Código de baixa</code>
                        </td>
                      </tr>
                    </table>

                    <table class="tbl">
                      <tr style="border-top:1px solid #000;">
                        <td rowspan="5" style="min-width:15.95cm;">
                          <span class="mnSt">
                            <div style="padding-left:1cm">
                              SAC CAIXA: 0800 726 0101 (informações,
                              reclamações, sugestões e elogios)<br />
                            </div>
                            <div style="padding-left:1cm">
                              Para pessoas com deficiência auditiva ou de fala:
                              0800 726 2492<br />
                            </div>
                            <div style="padding-left:1cm">
                              Ouvidoria: 0800 725 7474<br />
                            </div>
                            <div style="padding-left:1cm">caixa.gov.br</div>
                          </span>
                        </td>
                        <td style="min-width:4.85cm;">
                          <code>Autenticação Mecânica</code>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- ///////////////////////////////////////////////////////// -->
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          style="position:relative;display:table;clear:both;width:21.05cm;background:#fff;margin:0 auto;color:#000"
        >
          <br />
          <div id="codb"></div>

          <br />
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
//import { api } from "@/conf/api";
import barcode from "../../conf/barcode";
//import { datahoje } from "../../conf/utilitario";

export default {
  name: "AlgarOperacao",
  data() {
    return {
      NOMCLIEN: "",
      venc: "",
      VALPAGO: "",
      INSTRUCAO: "",
      DESCPF: "",
      acordo: "",
      VALORPRINCIPAL: "",
      VALORMULTA: "",
      VALORTXADM: "",
      VALORJUROS: "",
      VALORDESCONTO: "",
      DESAGENC: "",
      DESCONTA: "",
      DESAGENCDV: "",
      CODBANCO: "",
      DESBANCODV: "",
      AGENCIACONTA: "",
      DESENDER: "",
      DESNUMER: "",
      DESCOMPL: "",
      DESBAIRR: "",
      DESCIDAD: "",
      DESESTAD: "",
      DESCEP: "",
      datahoje: "",
      DESNUMDOC: "",
      NOSSONUMERO: "",
      LINHADIGITAVEL: "",
      CODIGOBARRA: "",
      barcodeVal2: "",
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  async beforeMount() {
    this.$store.dispatch("setMostraBotoes", { imprimir: true, voltar: true });
    this.pegarBoleto();
  },
  async mounted() {
    try {
      let boleto = this.$store.state.boleto;
      let barras = boleto.js.XML.DADOBOLETO.CODIGOBARRA._text;
      //console.log(barras);
      // await this.colocaValores2(boleto.js.XML.DADOBOLETO);
      barcode(window.$);
      window.$("#codb").barcode(
        {
          code: `${barras}`,
          crc: false,
        },
        "int25",
        {
          barWidth: 1,
          barHeight: 50,
          showHRI: false,
        }
      );
    } catch (e) {
      console.log(e);
      //this.$router.push("erroPage");
    }
  },
  methods: {
    async pegarBoleto() {
      try {
        let boleto = this.$store.state.boleto;
        //console.log(boleto);
        //let barras = boleto.js.XML.DADOBOLETO.CODIGOBARRA._text;
        //console.log(barras);
        await this.colocaValores2(boleto.js.XML.DADOBOLETO, boleto);
        /*
        barcode(window.$);
        window.$("#codb").barcode(
          {
            code: `${barras}`,
            crc: false,
          },
          "int25",
          {
            barWidth: 1,
            barHeight: 50,
            showHRI: false,
          }
        );
        */
      } catch (e) {
        console.log(e);
        //this.$router.push("erroPage");
      }
    },
    async colocaValores2(boleto, boleto2) {
      //console.log("boletoo", this.this.$store.state.boleto.data);
      let boleto3 = boleto2.js.XML;
      this.NOMCLIEN = boleto.NOMCLIEN._text;
      this.venc = boleto.DATVENCI._text;
      this.VALPAGO = boleto.VALPAGO._text;
      this.INSTRUCAO = boleto.INSTRUCAO._text;
      this.DESCPF = boleto.DESCPF._text;
      let acordo = boleto.INSTRUCAO._text.split(" ");
      this.acordo = acordo[2];

      this.VALORPRINCIPAL = boleto3.VALORPRINCIPAL._text;
      this.VALORMULTA = boleto3.VALORMULTA._text;
      this.VALORTXADM = boleto3.VALORTXADM._text;
      this.VALORJUROS = boleto3.VALORJUROS._text;
      this.VALORDESCONTO = boleto3.VALORDESCONTO._text;

      this.DESAGENC = boleto.DESAGENC._text;
      this.DESCONTA = boleto.DESCONTA._text;
      this.DESAGENCDV = boleto.DESAGENCDV._text;
      this.CODBANCO = boleto.CODBANCO._text;
      this.DESBANCODV = boleto.DESBANCODV._text;
      this.AGENCIACONTA = boleto.AGENCIACONTA._text;
      this.DESENDER = boleto.DESENDER._text;
      this.DESNUMER = boleto.DESNUMER._text;
      this.DESCOMPL = boleto.DESCOMPL._text;
      this.DESBAIRR = boleto.DESBAIRR._text;
      this.DESCIDAD = boleto.DESCIDAD._text;
      this.DESESTAD = boleto.DESESTAD._text;
      this.DESCEP = boleto.DESCEP._text;
      this.datahoje = boleto2.data_geracao;
      this.DESNUMDOC = boleto.DESNUMDOC._text;
      this.NOSSONUMERO = boleto.NOSSONUMERO._text;
      this.LINHADIGITAVEL = boleto.LINHADIGITAVEL._text;
      this.CODIGOBARRA = boleto.CODIGOBARRA._text;
      this.barcodeVal2 = boleto.CODIGOBARRA._text;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  color: #000;
  line-height: 90% !important;
}
.nm {
  font-size: 15px;
}
.mn2 {
  font-size: 17px;
}
.box10 {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  float: left;
  width: 45%;
  min-height: 260px;
  margin: 5px;
  border: 1px solid #ccc;
  padding: 12px;
  overflow: hidden;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(45, 88, 137, 0.75);
  -moz-box-shadow: 4px 4px 5px 0px rgba(45, 88, 137, 0.75);
  box-shadow: 4px 4px 5px 0px rgba(45, 88, 137, 0.75);
}
table td {
  padding: 0cm;
  border: none;
}
.vertical {
  height: 0.8cm;
  border-left: 1px solid #000;
  right: 0;
  bottom: 0;
  position: absolute;
}
.tbl {
  width: 100%;
  border: none;
}
.mnSt {
  font-size: 12px;
  font-weight: 100;
  line-height: 120% !important;
}
.mnStMn {
  font-size: 11px;
  font-weight: 100;
  line-height: 120% !important;
}
.tbl tr,
.tbl tr td {
  border: none;
  padding: 2px;
  height: 35px;
  height: 30px;
  line-height: 1;
}
.pagebreak {
  height: 12.5cm;
}
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 12px;
}
a img,
img {
  border: none;
}
ol,
ul {
  list-style: none;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
hr {
  border: 0px none;
  height: 1px;
  background-image: -moz-linear-gradient(
    left center,
    transparent,
    rgba(0, 0, 0, 0.75),
    transparent
  );
  margin-top: 4px;
  margin-bottom: 4px;
}
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
h1 {
  font-size: 18px;
}
h2 {
  font-size: 16pt;
}
/* --------------------------------
		geral
--------------------------------- */
html,
body {
  height: 100%;
  background: #f9f9f9;
}
i {
  color: red;
  font-weight: normal;
}
.space {
  clear: both;
  padding: 5px;
  width: 17cm;
  margin: 0 auto;
}
.content {
  margin: 0 auto;
  width: 17cm;
  position: relative;
}
.colM {
  width: 40%;
  float: left;
  text-align: center;
}
.colmn {
  width: 38%;
  text-align: center;
  float: left;
}
.col {
  float: right;
  text-align: center;
  width: 20%;
}
.col1 {
  width: 48%;
  float: left;
  text-align: left;
  padding-right: 1%;
}
.col2 {
  width: 48%;
  float: right;
  text-align: right;
  padding-left: 1%;
}
.long {
  min-height: 7.5cm;
}
.medium {
  min-height: 3cm;
}
.footer {
  position: relative;
  margin-top: 40px;
}
hr {
  border: 0;
  background-color: #fff;
  border-top: 1px dashed #000;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
code {
  color: #000;
  float: none;
  background: #fff none repeat scroll 0% 0%;
  font-weight: normal;
  font-size: 10px;
}
a {
  color: #2b75bf;
}
a:hover {
  color: #284a6b;
  text-decoration: none;
}
a:focus {
  color: #284a6b;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 5px;
}
/* --------------------------------
		Linhas tabelas e html
--------------------------------- */
table {
  font-weight: 700;
  width: 17cm;
  margin: 0 auto;
  border-collapse: collapse; /* CSS2 */
  background: #fff;
}
table b {
  font-weight: 800;
}
table td {
  vertical-align: top;
  min-width: 4cm;
  border: 2px solid black;
  padding: 5px;
  height: 18px;
}
table th {
  border: 12px solid black;
  background: #fff;
  height: 18px;
}
.semBorda td {
  border: none;
}
/* --------------------------------
			formularios
--------------------------------- */
input {
  float: right;
  background: #3498db;
  margin: 5px;
  border-radius: 4px;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
}
input:hover {
  background: #1179be;
}
/* ==========================================================================
   Estilos para impressÃ£o
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  body {
    font: 12pt Georgia, "Times New Roman", Times, serif;
    color: #000;
  }
  h1 {
    font-size: 17pt;
  }
  h2 {
    font-size: 16pt;
  }
  h3 {
    font-size: 14pt;
  }
  q:after {
    content: " (" attr(cite) ")";
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a:link:after,
  a:visited:after {
    content: " (" attr(href) ") ";
  }
  p a {
    word-wrap: break-word;
  }
  p {
    widows: 3;
  }
  p {
    orphans: 3;
  }
  .page-break {
    page-break-before: always;
  }
  .notprint {
    visibility: hidden;
    display: none;
  }
  .pb {
    filter: -ms-grayscale(100%) !important;
    filter: -moz-grayscale(100%) !important;
    filter: -webkit-grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
}
.folha {
  page-break-after: always;
  max-height: 27.5cm;
}
</style>
